.content-post {
  min-height: calc(100vh - 480px);
  background: var(--color-background);
  padding-top: 30px;
  padding-bottom: 30px;
}

.list-item {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid var(--color-border);
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
  overflow: hidden;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    transition: 0.3s;
    border-color: var(--color-link-hover);
    text-decoration: none;
    color: var(--color-text);
  }

  .list-item-icon {
    flex-basis: 100px;
    padding-right: 20px;
    font-size: 2rem;
    color: var(--color-theme);
  }

  .list-post-title {
    font-size: 22px;
    transition: 0.3s;
    color: var(--color-text);
  }
  .list-post-description {
    font-size: 16px;
  }

  .list-post-written {
    span {
      font-weight: bold;
    }
  }

  small {
    font-size: 12px;
    opacity: 0.7;
  }
}

.post-title {
  margin-top: 15px;
  color: var(--color-text);
}

.post-list-tags {
  margin: 10px;
  .post-tag {
    padding: 5px 10px;
    color: white;
    background-color: var(--color-theme-grey);
    border-radius: 50px;
  }
}
