.footer {
  min-height: 200px;
  background: var(--color-background);
  color: var(--color-text);
  overflow: hidden;

  .footer--block {
    .underline {
      text-decoration: underline;
    }
  }
}
