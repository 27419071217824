.menu {
  background: var(--color-theme-lighter);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  a {
    color: var(--color-title);
    text-decoration: none;
    span {
      font-weight: bold;
    }
  }
}

.menu--content {
  height: 80px;
}

.back-to-site {
  font-size: 14px;
  @media screen and (max-width: 480px) {
    img {
      width: 22px;
    }
  }
  .back-to-site-text {
    margin-left: 5px;
    transition: 0.3s;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
}
